import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';

import { HomeSection } from './HomeSection';
import { VisionSection } from './VisionSection';
import { ChooseUsSection } from './ChooseUsSection';
import { ServicesSection } from './ServicesSection';
import { ApproachesSection } from './ApproachesSection';
import { FinalSection } from './FinalSection';

export const LandingPage: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const onScroll = () => {
      const hides = document.getElementsByClassName(
        '__delay',
      ) as unknown as any[];
      for (const el of hides) {
        el.style.visibility = 'hidden';
      }

      const value = window.scrollY;
      const els = document.getElementsByClassName(
        '__animated',
      ) as unknown as any[];

      for (const el of els) {
        if (value + window.innerHeight >= getOffsetTop(el)) {
          if (!el.classList.contains('animate__animated')) {
            const className: string = el.className;
            const classNames: string[] = className.split(' ');
            let delay = 0;

            for (let i = 0; i < classNames.length; i++) {
              if (classNames[i].includes('__delay-100ms')) {
                delay = 100;
                continue;
              }
              if (classNames[i].includes('__delay-200ms')) {
                delay = 200;
                continue;
              }
              if (classNames[i].includes('__delay-300ms')) {
                delay = 300;
                continue;
              }
              if (classNames[i].includes('__delay-400ms')) {
                delay = 400;
                continue;
              }
              if (classNames[i].includes('__')) {
                classNames[i] = `animate${classNames[i]}`;
              }
            }

            if (delay) {
              setTimeout(() => {
                el.style.visibility = '';
                el.className = classNames.join(' ');
              }, delay);
            } else {
              el.className = classNames.join(' ');
            }
          }
        }
      }
    };
    window.addEventListener('scroll', onScroll);

    onScroll();

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    if (location.hash) {
      scroller.scrollTo(location.hash.slice(1), {
        duration: 200,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -100,
      });
    }
  }, [location]);

  return (
    <>
      <HomeSection />
      <VisionSection />
      <ChooseUsSection />
      <ServicesSection />
      <ApproachesSection />
      <FinalSection />
    </>
  );
};

function getOffsetTop(element: HTMLElement): number {
  let offsetTop = 0;
  while (element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent as HTMLElement;
  }
  return offsetTop;
}
