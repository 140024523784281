import { Components, Theme } from '@mui/material';

export const MuiMenu: Components<Omit<Theme, 'components'>>['MuiMenu'] = {
  styleOverrides: {
    root: {},
    paper: {
      boxShadow: '0px 0px 12px 8px rgba(222, 222, 222, 0.45)',
    },
  },
};
