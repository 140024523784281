import React from 'react';
import {
  Box,
  Container,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import img from '../../assets/images/image13.png';

const Image = styled('img')(() => ({
  objectFit: 'cover',
}));

export const FinalSection: React.FC = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box position="relative" pt={6.25} pb={10}>
      <Container>
        <Stack direction={isSm ? 'column' : 'row'}>
          <Box
            bgcolor="#FFF8F1"
            boxSizing="border-box"
            sx={{
              ...(isSm
                ? {
                    flex: 1,
                    borderTopLeftRadius: 24,
                    borderTopRightRadius: 24,
                    px: 4,
                    py: 6,
                  }
                : {
                    width: '66%',
                    borderTopLeftRadius: 24,
                    borderBottomLeftRadius: 24,
                    px: 8,
                    py: 16,
                  }),
            }}
          >
            <Typography
              variant="h2"
              fontSize={40}
              fontWeight={700}
              className="__animated __zoomIn"
            >
              UNLOCK THE POTENTIAL OF&nbsp;
              <Typography
                component="span"
                fontSize="inherit"
                fontWeight="inherit"
                bgcolor="primary.main"
              >
                &nbsp;REAL ASSETS&nbsp;
              </Typography>
            </Typography>
            <Typography
              mt={2}
              color="grey.600"
              className="__animated __zoomIn __delay __delay-100ms"
            >
              Asset tokenization offers a world of benefits increased liquidity
              , fractional ownership, and enhanced accessibility. Discover why
              tokenization is the future of asset management.
            </Typography>
          </Box>
          <Image
            src={img}
            alt="Real Assets"
            sx={
              isSm
                ? {
                    width: '100%',
                    maxHeight: 200,
                    borderBottomLeftRadius: 24,
                    borderBottomRightRadius: 24,
                  }
                : {
                    width: '34%',
                    maxWidth: 400,
                    borderTopRightRadius: 24,
                    borderBottomRightRadius: 24,
                  }
            }
          />
        </Stack>
      </Container>
    </Box>
  );
};
