import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

export const AppContentContainer: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <Box
      pt="90px"
      width="100%"
      sx={{
        overflowX: 'hidden',
      }}
    >
      {children}
    </Box>
  );
};
