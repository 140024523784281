import React, { useCallback } from 'react';
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Stack,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material';
import { scroller } from 'react-scroll';

import image from '../../assets/images/image1.png';
import bg from '../../assets/images/image14.png';

const Image = styled('img')(() => ({}));

export const HomeSection: React.FC = () => {
  const isSm = useMediaQuery('(max-width: 1046px)');

  const handleLearnMore = useCallback(() => {
    scroller.scrollTo('services', {
      smooth: true,
      duration: 200,
      offset: -100,
    });
  }, []);

  return (
    <Box
      id="home"
      position="relative"
      minHeight="calc(100vh - 90px)"
      sx={{
        backgroundColor: '#FBFBFB',
        backgroundImage: `url(${bg})`,
        backgroundPosition: 'top -100px right 0px',
        backgroundRepeat: 'no-repeat',
        ...(isSm && {
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }),
      }}
    >
      <Container
        sx={{
          pt: isSm ? 8 : 18,
          pb: isSm ? 8 : 18,
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={7}>
            <Chip
              variant="filled"
              color="primary"
              label="Transforming Real Assets Into Digital Opportunities"
              className="__animated __flipInY"
              sx={{
                height: 'auto',
                '& .MuiChip-label': {
                  whiteSpace: 'initial',
                  overflow: 'unset',
                },
              }}
            />
            <Typography
              variant="h1"
              fontSize={60}
              fontWeight={700}
              lineHeight={1.2}
              color="grey.600"
              mt={2}
              mb={2.5}
              className="__animated __fadeInLeft"
            >
              Welcome to{' '}
              <Box component="span" color="primary.main">
                ASSNTURE
              </Box>
            </Typography>
            <Typography
              color="grey.500"
              lineHeight={1.5}
              maxWidth={550}
              className="__animated __fadeInLeft __delay __delay-200ms"
            >
              At ASSNTURE, we are at the forefront of the digital revolution,
              offering cutting-edge solutions for tokenizing real-world assets.
              Our mission is to bridge the gap between traditional finance and
              blockchain technology, unlocking new possibilities for investors,
              asset owners, and businesses.
            </Typography>
            <Box mt={6}>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  borderColor: 'grey.300',
                  color: 'grey.700',
                }}
                onClick={handleLearnMore}
                className="__animated __fadeInLeft __delay __delay-300ms"
              >
                Learn More
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Stack height="100%" direction="column" justifyContent="center">
              <Image
                src={image}
                alt="ASSNTURE"
                sx={{
                  width: '100%',
                }}
                className="__animated __fadeInRight __delay __delay-300ms"
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
