import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { AppHeader } from './app-layout/AppHeader';
import { AppContentContainer } from './app-layout/AppContentContainer';
import { AppFooter } from './app-layout/AppFooter';

export const AppLayout: React.FC = () => {
  const location = useLocation();

  const [key, setKey] = useState(Date.now());

  useEffect(() => {
    setKey(Date.now());

    window.scrollTo({
      top: 0,
    });
  }, [location]);

  return (
    <React.Fragment key={`root-${key}`}>
      <AppHeader />
      <AppContentContainer>
        <Outlet />
        <AppFooter />
      </AppContentContainer>
    </React.Fragment>
  );
};
