import React, { useCallback } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ArrowForwardOutlined } from '@mui/icons-material';
import { scroller } from 'react-scroll';

import img from '../../assets/images/image2.png';

const Image = styled('img')(() => ({
  width: '100%',
  maxWidth: '100%',
}));

export const VisionSection: React.FC = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLearnMore = useCallback(() => {
    scroller.scrollTo('services', {
      smooth: true,
      duration: 200,
      offset: -100,
    });
  }, []);

  return (
    <>
      <Box id="vision" position="relative" py={6.25}>
        <Container>
          <Grid container columnSpacing={4} rowSpacing={8}>
            <Grid item xs={12} md={6}>
              <Stack direction="column" justifyContent="center" height="100%">
                <Typography
                  variant="h2"
                  fontSize={40}
                  fontWeight={700}
                  className="__animated __fadeInLeft"
                >
                  OUR VISION
                  <br />
                  DISCOVER&nbsp;
                  {isXs && <br />}
                  <Typography
                    component="span"
                    bgcolor="primary.main"
                    fontSize={40}
                    fontWeight={700}
                  >
                    &nbsp;ASSNTURE&nbsp;
                  </Typography>
                </Typography>
                <Typography
                  mt={3}
                  color="grey.500"
                  className="__animated __fadeInLeft"
                >
                  ASSNTURE is a leading innovator in the field of digital asset
                  infrastructure. Founded By a team of industry veterans, we
                  combine deep expertise in finance, blockchain, and technology
                  to create a seamless bridge between the real and digital
                  worlds.
                </Typography>
                <Box mt={3}>
                  <Button
                    className="__animated __fadeInLeft"
                    endIcon={<ArrowForwardOutlined />}
                    onClick={handleLearnMore}
                  >
                    Learn More
                  </Button>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Image
                src={img}
                alt="Our Vision"
                className="__animated __zoomIn"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
