import { Components, Theme } from '@mui/material';

export const MuiChip: Components<Omit<Theme, 'components'>>['MuiChip'] = {
  styleOverrides: {
    root: {
      fontWeight: 500,
      fontSize: 14,
      height: 28,
    },
    label: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    filledPrimary: ({ theme }) => ({
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.bg['primary-highlight'],
    }),
  },
};
