import React from 'react';
import {
  Button,
  Container,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Menu,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { Link } from 'react-scroll';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { NavLink, useLocation } from 'react-router-dom';
import {
  AppsOutlined,
  CloudSyncOutlined,
  EmailOutlined,
  HomeOutlined,
  InfoOutlined,
  MenuOutlined,
} from '@mui/icons-material';

import branding from '../../../assets/logos/primary-brand.png';

const HeaderContainer = styled(Paper)(({ theme }) => ({
  width: '100%',
  height: 90,
  borderRadius: 0,
  position: 'fixed',
  left: 0,
  top: 0,
  zIndex: 100,
  background: theme.palette.common.white,
}));

const LogoImage = styled('img')(() => ({
  width: 170,
  height: 40,
  objectFit: 'contain',
}));

const HeaderLink = styled(Link)(({ theme }) => ({
  color: theme.palette.grey['600'],
  textDecoration: 'none',
  cursor: 'pointer',

  '&:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  '&:active': {
    color: theme.palette.primary.dark,
  },
}));

const NavigationLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.grey['600'],
  textDecoration: 'none',
  cursor: 'pointer',

  '&:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  '&:active': {
    color: theme.palette.primary.dark,
  },
}));

const ResponsiveMenu = styled(Menu)(() => ({
  '.MuiMenuItem-root': {
    paddingTop: 0,
    paddingBottom: 0,
    a: {
      height: 48,
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
}));

export const AppHeader: React.FC = () => {
  const location = useLocation();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <HeaderContainer>
      <Container sx={{ height: '100%' }}>
        <Stack
          height="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <NavLink to="/">
            <LogoImage src={branding} alt="ASSNTURE" />
          </NavLink>
          {isSm ? (
            <PopupState variant="popover">
              {(popupState) => (
                <React.Fragment>
                  <IconButton color="primary" {...bindTrigger(popupState)}>
                    <MenuOutlined />
                  </IconButton>
                  <ResponsiveMenu {...bindMenu(popupState)}>
                    {location.pathname === '/'
                      ? [
                          <MenuItem key="nav-home">
                            <Link
                              to="home"
                              smooth
                              duration={200}
                              offset={-100}
                              onClick={popupState.close}
                            >
                              <ListItemIcon>
                                <HomeOutlined />
                              </ListItemIcon>
                              <ListItemText>Home</ListItemText>
                            </Link>
                          </MenuItem>,
                          <MenuItem key="nav-choose-us">
                            <Link
                              to="choose-us"
                              smooth
                              duration={200}
                              offset={-100}
                              onClick={popupState.close}
                            >
                              <ListItemIcon>
                                <InfoOutlined />
                              </ListItemIcon>
                              <ListItemText>About</ListItemText>
                            </Link>
                          </MenuItem>,
                          <MenuItem key="nav-services">
                            <Link
                              to="services"
                              smooth
                              duration={200}
                              offset={-100}
                              onClick={popupState.close}
                            >
                              <ListItemIcon>
                                <AppsOutlined />
                              </ListItemIcon>
                              <ListItemText>Services</ListItemText>
                            </Link>
                          </MenuItem>,
                          <MenuItem key="nav-how-it-works">
                            <Link
                              to="how-it-works"
                              smooth
                              duration={200}
                              offset={-100}
                              onClick={popupState.close}
                            >
                              <ListItemIcon>
                                <CloudSyncOutlined />
                              </ListItemIcon>
                              <ListItemText>How it works</ListItemText>
                            </Link>
                          </MenuItem>,
                        ]
                      : [
                          <MenuItem
                            key="nav-home"
                            component={NavLink}
                            to="/"
                            onClick={popupState.close}
                          >
                            <ListItemIcon>
                              <HomeOutlined />
                            </ListItemIcon>
                            <ListItemText>Home</ListItemText>
                          </MenuItem>,
                          <MenuItem
                            key="nav-choose-us"
                            component={NavLink}
                            to="/#choose-us"
                            onClick={popupState.close}
                          >
                            <ListItemIcon>
                              <InfoOutlined />
                            </ListItemIcon>
                            <ListItemText>About</ListItemText>
                          </MenuItem>,
                          <MenuItem
                            key="nav-services"
                            component={NavLink}
                            to="/#services"
                            onClick={popupState.close}
                          >
                            <ListItemIcon>
                              <AppsOutlined />
                            </ListItemIcon>
                            <ListItemText>Services</ListItemText>
                          </MenuItem>,
                          <MenuItem
                            key="nav-how-it-works"
                            component={NavLink}
                            to="/#how-it-works"
                            onClick={popupState.close}
                          >
                            <ListItemIcon>
                              <CloudSyncOutlined />
                            </ListItemIcon>
                            <ListItemText>How it works</ListItemText>
                          </MenuItem>,
                        ]}
                    <Divider />
                    <MenuItem
                      component="a"
                      href="mailto:hello@assnture.com"
                      onClick={popupState.close}
                    >
                      <ListItemIcon>
                        <EmailOutlined />
                      </ListItemIcon>
                      <ListItemText>Contact Us</ListItemText>
                    </MenuItem>
                  </ResponsiveMenu>
                </React.Fragment>
              )}
            </PopupState>
          ) : (
            <React.Fragment>
              {location.pathname === '/' ? (
                <Stack direction="row" gap={3.75}>
                  <HeaderLink to="home" smooth duration={200} offset={-100}>
                    <Typography component="span" fontWeight={500}>
                      Home
                    </Typography>
                  </HeaderLink>
                  <HeaderLink
                    to="choose-us"
                    smooth
                    duration={200}
                    offset={-100}
                  >
                    <Typography component="span" fontWeight={500}>
                      About
                    </Typography>
                  </HeaderLink>
                  <HeaderLink to="services" smooth duration={200} offset={-100}>
                    <Typography component="span" fontWeight={500}>
                      Services
                    </Typography>
                  </HeaderLink>
                  <HeaderLink
                    to="how-it-works"
                    smooth
                    duration={200}
                    offset={-100}
                  >
                    <Typography component="span" fontWeight={500}>
                      How it works
                    </Typography>
                  </HeaderLink>
                </Stack>
              ) : (
                <Stack direction="row" gap={3.75}>
                  <NavigationLink to="/">
                    <Typography component="span" fontWeight={500}>
                      Home
                    </Typography>
                  </NavigationLink>
                  <NavigationLink to="/#choose-us">
                    <Typography component="span" fontWeight={500}>
                      About
                    </Typography>
                  </NavigationLink>
                  <NavigationLink to="/#services">
                    <Typography component="span" fontWeight={500}>
                      Services
                    </Typography>
                  </NavigationLink>
                  <NavigationLink to="/#how-it-works">
                    <Typography component="span" fontWeight={500}>
                      How it works
                    </Typography>
                  </NavigationLink>
                </Stack>
              )}
              <Button
                variant="contained"
                size="large"
                component="a"
                href="mailto:hello@assnture.com"
              >
                Contact Us
              </Button>
            </React.Fragment>
          )}
        </Stack>
      </Container>
    </HeaderContainer>
  );
};
