import React from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import { ReactComponent as SvgFacebook } from '../../../assets/social-icons/Facebook.svg';
import { ReactComponent as SvgInstagram } from '../../../assets/social-icons/Instagram.svg';
import { ReactComponent as SvgTwitter } from '../../../assets/social-icons/Twitter.svg';
import { ReactComponent as SvgLinkedin } from '../../../assets/social-icons/LinkedIn.svg';
import { ReactComponent as SvgYoutube } from '../../../assets/social-icons/YouTube.svg';
import branding from '../../../assets/logos/secondary-brand.png';

const LogoImage = styled('img')(() => ({
  width: 204,
  height: 48,
  objectFit: 'contain',
}));

const FooterMainLink = styled(ScrollLink)(({ theme }) => ({
  color: theme.palette.common.white,
  textDecoration: 'none',
  cursor: 'pointer',

  '&:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  '&:active': {
    color: theme.palette.primary.dark,
  },
}));

const FooterNavigationLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.common.white,
  textDecoration: 'none',
  cursor: 'pointer',

  '&:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  '&:active': {
    color: theme.palette.primary.dark,
  },
}));

const FooterAdditionalLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.grey['300'],
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  '&:active': {
    color: theme.palette.primary.dark,
  },
}));

export const AppFooter: React.FC = () => {
  const location = useLocation();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box bgcolor="bg.footer" pt={8} pb={4}>
      <Container>
        <Stack direction="column" alignItems="center" gap={4}>
          <LogoImage src={branding} alt="ASSNTURE" />
          <Typography color="grey.200" textAlign="center">
            Level 24, Al Sila Tower, ADGM Square, Abu Dhabi, UAE
          </Typography>
          <Stack
            direction={isXs ? 'column' : 'row'}
            alignItems="center"
            gap={isXs ? 2 : 3.75}
          >
            {location.pathname === '/' ? (
              <>
                <FooterMainLink to="home" smooth duration={200} offset={-100}>
                  <Typography component="span">Home</Typography>
                </FooterMainLink>
                <FooterMainLink
                  to="choose-us"
                  smooth
                  duration={200}
                  offset={-100}
                >
                  <Typography component="span">About</Typography>
                </FooterMainLink>
                <FooterMainLink
                  to="services"
                  smooth
                  duration={200}
                  offset={-100}
                >
                  <Typography component="span">Services</Typography>
                </FooterMainLink>
                <FooterMainLink
                  to="how-it-works"
                  smooth
                  duration={200}
                  offset={-100}
                >
                  <Typography component="span">How it works</Typography>
                </FooterMainLink>
              </>
            ) : (
              <>
                <FooterNavigationLink to="/">
                  <Typography component="span">Home</Typography>
                </FooterNavigationLink>
                <FooterNavigationLink to="/#choose-us">
                  <Typography component="span">About</Typography>
                </FooterNavigationLink>
                <FooterNavigationLink to="/#services">
                  <Typography component="span">Services</Typography>
                </FooterNavigationLink>
                <FooterNavigationLink to="/#how-it-works">
                  <Typography component="span">How it works</Typography>
                </FooterNavigationLink>
              </>
            )}
          </Stack>
        </Stack>
        <Divider
          sx={{
            bgcolor: 'grey.600',
            mt: 8,
            mb: 4,
          }}
        />
        <Grid container rowSpacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Stack
              direction="row"
              justifyContent={isSm ? 'center' : 'flex-start'}
              gap={2}
            >
              <FooterAdditionalLink to="/terms">
                <Typography component="span">Terms of Use</Typography>
              </FooterAdditionalLink>
              <FooterAdditionalLink to="/privacy-policy">
                <Typography component="span">Privacy Policy</Typography>
              </FooterAdditionalLink>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography
              textAlign={isSm ? 'center' : 'right'}
              color="grey.300"
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              © {new Date().getFullYear()} ASSNTURE. All rights reserved.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} display="none">
            <Stack
              direction="row"
              justifyContent={isSm ? 'center' : 'flex-end'}
              gap={1.5}
            >
              <Link href="#">
                <SvgFacebook />
              </Link>
              <Link href="#">
                <SvgTwitter />
              </Link>
              <Link href="#">
                <SvgInstagram />
              </Link>
              <Link href="#">
                <SvgLinkedin />
              </Link>
              <Link href="#">
                <SvgYoutube />
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
