import React from 'react';
import { Box, Container, Grid, Typography, styled } from '@mui/material';

import img1 from '../../assets/images/image8.png';
import img2 from '../../assets/images/image9.png';
import img3 from '../../assets/images/image10.png';
import img4 from '../../assets/images/image11.png';
import img5 from '../../assets/images/image12.png';

const ApproachContainer = styled(Box)(() => ({
  height: '100%',
  boxSizing: 'border-box',
  padding: '40px 24px',
  borderRadius: 8,
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 0px 4px 1px rgba(206, 206, 206, 0.25)',
}));

const ApproachImage = styled('img')(() => ({
  maxWidth: '100%',
  height: 200,
  marginLeft: '50%',
  transform: 'translate(-50%, 0)',
}));

export const ApproachesSection: React.FC = () => {
  return (
    <Box id="how-it-works" position="relative" py={6.25} bgcolor="#F8FAF5">
      <Container>
        <Typography
          variant="h2"
          fontSize={40}
          fontWeight={700}
          textAlign="center"
          className="__animated __flipInX"
        >
          HOW&nbsp;
          <Typography
            component="span"
            fontSize="inherit"
            fontWeight="inherit"
            bgcolor="primary.main"
          >
            &nbsp;IT WORKS&nbsp;
          </Typography>
        </Typography>
        <Box pt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4}>
              <ApproachContainer className="__animated __backInUp">
                <ApproachImage src={img1} alt="Asset Valuation" />
                <Typography
                  mt={3}
                  variant="h6"
                  fontSize={24}
                  fontWeight={700}
                  color="primary.main"
                >
                  1 Asset Valuation:
                </Typography>
                <Typography mt={1.5} color="grey.600">
                  Choose your real-world asset (e.g., real estate, commodities,
                  Sukuk) and conduct a professional valuation to determine its
                  market worth.
                </Typography>
              </ApproachContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ApproachContainer className="__animated __backInUp __delay __delay-100ms">
                <ApproachImage src={img2} alt="Legal/Compliance Setup" />
                <Typography
                  mt={3}
                  variant="h6"
                  fontSize={24}
                  fontWeight={700}
                  color="primary.main"
                >
                  2 Legal/Compliance Setup:
                </Typography>
                <Typography mt={1.5} color="grey.600">
                  With our assured processes we structure the asset legally for
                  tokenization and ensure compliance with relevant regulations.
                </Typography>
              </ApproachContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ApproachContainer className="__animated __backInUp __delay __delay-200ms">
                <ApproachImage src={img3} alt="Token Creation/Issuance" />
                <Typography
                  mt={3}
                  variant="h6"
                  fontSize={24}
                  fontWeight={700}
                  color="primary.main"
                >
                  3 Token Creation/Issuance:
                </Typography>
                <Typography mt={1.5} color="grey.600">
                  Using OPTIMUS you can mint digital tokens representing
                  fractional ownership of the asset on a blockchain platform.
                </Typography>
              </ApproachContainer>
            </Grid>
            <Grid item xs={2} sm={2} md={0} />
            <Grid item xs={12} sm={12} md={4}>
              <ApproachContainer className="__animated __backInUp __delay __delay-300ms">
                <ApproachImage src={img4} alt="Onboard Your Investors" />
                <Typography
                  mt={3}
                  variant="h6"
                  fontSize={24}
                  fontWeight={700}
                  color="primary.main"
                >
                  4 Onboard Your Investors:
                </Typography>
                <Typography mt={1.5} color="grey.600">
                  Onboard and verify investors, allowing them to purchase
                  tokens, or take advantage of ASSNTURE’S institutional
                  investors, securing funds for the asset.
                </Typography>
              </ApproachContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ApproachContainer className="__animated __backInUp __delay __delay-400ms">
                <ApproachImage src={img5} alt="Release Liquidity" />
                <Typography
                  mt={3}
                  variant="h6"
                  fontSize={24}
                  fontWeight={700}
                  color="primary.main"
                >
                  5 Release Liquidity:
                </Typography>
                <Typography mt={1.5} color="grey.600">
                  Access the capital raised from tokenization sales, enabling
                  you to unlock liquidity from your asset while retaining
                  partial ownership.
                </Typography>
              </ApproachContainer>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};
