import { createTheme } from '@mui/material';

import { MuiPaper } from './themes/MuiPaper';
import { MuiButton } from './themes/MuiButton';
import { MuiMenu } from './themes/MuiMenu';
import { MuiChip } from './themes/MuiChip';
import { MuiTypography } from './themes/MuiTypography';

export const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: '#F07030',
    },
    common: {
      white: '#FFFFFF',
    },
    grey: {
      '200': '#D7D7D7',
      '300': '#D0D5DD',
      '500': '#787878',
      '600': '#484848',
      '700': '#344054',
      '900': '#101828',
    },
    bg: {
      footer: '#33312F',
      'primary-highlight': '#FFF9F6',
    },
  },
  components: {
    MuiPaper,
    MuiButton,
    MuiMenu,
    MuiChip,
    MuiTypography,
  },
});
