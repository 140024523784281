import React from 'react';
import { Container, Typography as MuiTypography, styled } from '@mui/material';

const Typography = styled(MuiTypography)(() => ({
  color: '#303030',
}));

export const PrivacyPolicyPage: React.FC = () => {
  return (
    <Container
      sx={{
        pt: 10,
        pb: 10,
      }}
      maxWidth="md"
    >
      <Typography variant="h4" fontWeight={700}>
        Privacy Policy for ASSNTURE Limited - ADGM and assnture.com
      </Typography>
      <Typography>[Effective Date]: 3rd June 2024</Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Introduction
      </Typography>
      <Typography mt={1}>
        ASSNTURE Limited - ADGM (&quot;we&quot;, &quot;us&quot;,
        &quot;our&quot;) is committed to protecting your privacy. This Privacy
        Policy explains how we collect, use, disclose, and safeguard your
        information when you visit our website, assnture.com, and any other
        media form, media channel, mobile website, or mobile application related
        or connected thereto (collectively, the “Site”).
      </Typography>
      <Typography mt={1.5}>
        Please read this privacy policy carefully. If you do not agree with the
        terms of this privacy policy, please do not access the Site.
      </Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Information We Collect
      </Typography>
      <Typography variant="h6" fontWeight={500} mt={2}>
        Personal Data
      </Typography>
      <Typography mt={1}>
        We may collect personally identifiable information (PII) that you
        voluntarily provide to us when you register on the Site, express an
        interest in obtaining information about us or our products and services,
        participate in activities on the Site (such as posting messages in our
        online forums or entering competitions, contests, or giveaways), or
        otherwise contact us. This may include:
        <br />
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Telephone number</li>
          <li>Postal address</li>
          <li>Job title</li>
          <li>Any other information you provide to us voluntarily</li>
        </ul>
      </Typography>
      <Typography variant="h6" fontWeight={500} mt={2}>
        Non-Personal Data
      </Typography>
      <Typography mt={1}>
        We may also collect non-personally identifiable information (Non-PII)
        about you automatically when you visit our Site. This may include:
        <br />
        <ul>
          <li>IP address</li>
          <li>Browser type</li>
          <li>Operating system</li>
          <li>Access times</li>
          <li>Pages viewed directly before and after accessing the Site</li>
        </ul>
      </Typography>
      <Typography variant="h6" fontWeight={500} mt={2}>
        Cookies and Tracking Technologies
      </Typography>
      <Typography mt={1}>
        We use cookies and similar tracking technologies to track the activity
        on our Site and hold certain information. Cookies are files with a small
        amount of data which may include an anonymous unique identifier. You can
        instruct your browser to refuse all cookies or to indicate when a cookie
        is being sent. However, if you do not accept cookies, you may not be
        able to use some portions of our Site.
      </Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Use of Your Information
      </Typography>
      <Typography mt={1}>
        We use the information we collect in the following ways:
        <br />
        <ul>
          <li>To provide, operate, and maintain our Site</li>
          <li>To improve, personalize, and expand our Site</li>
          <li>To understand and analyze how you use our Site</li>
          <li>
            To develop new products, services, features, and functionality
          </li>
          <li>
            To communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the Site, and for
            marketing and promotional purposes
          </li>
          <li>To process your transactions and send you related information</li>
          <li>To find and prevent fraud</li>
          <li>For compliance with legal obligations</li>
        </ul>
      </Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Disclosure of Your Information
      </Typography>
      <Typography mt={1}>
        We may share information we have collected about you in certain
        situations. Your information may be disclosed as follows:
      </Typography>
      <Typography variant="h6" fontWeight={500} mt={2}>
        By Law or to Protect Rights
      </Typography>
      <Typography mt={1}>
        If we believe the release of information about you is necessary to
        respond to legal process, to investigate or remedy potential violations
        of our policies, or to protect the rights, property, and safety of
        others, we may share your information as permitted or required by any
        applicable law, rule, or regulation.
      </Typography>
      <Typography variant="h6" fontWeight={500} mt={2}>
        Business Transfers
      </Typography>
      <Typography mt={1}>
        We may share or transfer your information in connection with, or during
        negotiations of, any merger, sale of company assets, financing, or
        acquisition of all or a portion of our business to another company.
      </Typography>
      <Typography variant="h6" fontWeight={500} mt={2}>
        Third-Party Service Providers
      </Typography>
      <Typography mt={1}>
        We may share your information with third parties that perform services
        for us or on our behalf, including payment processing, data analysis,
        email delivery, hosting services, customer service, and marketing
        assistance.
      </Typography>
      <Typography variant="h6" fontWeight={500} mt={2}>
        Affiliates
      </Typography>
      <Typography mt={1}>
        We may share your information with our affiliates, in which case we will
        require those affiliates to honor this Privacy Policy. Affiliates
        include our parent company and any subsidiaries, joint venture partners,
        or other companies that we control or that are under common control with
        us.
      </Typography>
      <Typography variant="h6" fontWeight={500} mt={2}>
        With Your Consent
      </Typography>
      <Typography mt={1}>
        We may disclose your personal information for any other purpose with
        your consent.
      </Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Security of Your Information
      </Typography>
      <Typography mt={1}>
        We use administrative, technical, and physical security measures to help
        protect your personal information. While we have taken reasonable steps
        to secure the personal information you provide to us, please be aware
        that despite our efforts, no security measures are perfect or
        impenetrable, and no method of data transmission can be guaranteed
        against any interception or other types of misuse.
      </Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Your Data Protection Rights
      </Typography>
      <Typography mt={1}>
        Depending on your location, you may have the following rights regarding
        your personal information:
        <br />
        <ul>
          <li>
            The right to access – You have the right to request copies of your
            personal data.
          </li>
          <li>
            The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate or complete
            information you believe is incomplete.
          </li>
          <li>
            The right to erasure – You have the right to request that we erase
            your personal data, under certain conditions.
          </li>
          <li>
            The right to restrict processing – You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions.
          </li>
          <li>
            The right to object to processing – You have the right to object to
            our processing of your personal data, under certain conditions.
          </li>
          <li>
            The right to data portability – You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.
          </li>
        </ul>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us at our contact
        details below.
      </Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Links to Other Websites
      </Typography>
      <Typography mt={1}>
        Our Site may contain links to other websites that are not operated by
        us. If you click on a third-party link, you will be directed to that
        third party’s site.
        <br />
        We strongly advise you to review the privacy policy of every site you
        visit. We have no control over and assume no responsibility for the
        content, privacy policies, or practices of any third-party sites or
        services.
      </Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Changes to This Privacy Policy
      </Typography>
      <Typography mt={1}>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. You are
        advised to review this Privacy Policy periodically for any changes.
        Changes to this Privacy Policy are effective when they are posted on
        this page.
      </Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Contact Us
      </Typography>
      <Typography mt={1}>
        If you have any questions about this Privacy Policy, please contact us:
        <br />
        <ul>
          <li>
            By email: <a href="mailto:hello@assnture.com">hello@assnture.com</a>
          </li>
          <li>
            By visiting this page on our website:{' '}
            <a href="https://assnture.com">https://assnture.com</a>
          </li>
        </ul>
        Thank you for visiting ASSNTURE Limited - ADGM and using assnture.com.
      </Typography>
      <Typography mt={2}>
        This privacy policy has been created to ensure your personal data is
        handled in a transparent, secure, and lawful manner. If you have any
        questions or concerns, please reach out using the contact information
        provided above.
      </Typography>
    </Container>
  );
};
