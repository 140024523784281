import React from 'react';
import { Container, Typography as MuiTypography, styled } from '@mui/material';

const Typography = styled(MuiTypography)(() => ({
  color: '#303030',
}));

export const TermsPage: React.FC = () => {
  return (
    <Container
      sx={{
        pt: 10,
        pb: 10,
      }}
      maxWidth="md"
    >
      <Typography variant="h4" fontWeight={700}>
        Terms of Use for assnture.com
      </Typography>
      <Typography>[Effective Date]: 3rd June 2024</Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Introduction
      </Typography>
      <Typography mt={1}>
        Welcome to assnture.com (the &quot;Site&quot;), operated by ASSNTURE
        Limited - ADGM (&quot;we&quot;, &quot;us&quot;, &quot;our&quot;). These
        Terms of Use (&quot;Terms&quot;) govern your access to and use of the
        Site. By accessing or using the Site, you agree to be bound by these
        Terms. If you do not agree with these Terms, please do not use the Site.
      </Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Use of the Site
      </Typography>
      <Typography variant="h6" fontWeight={500} mt={2}>
        Eligibility
      </Typography>
      <Typography mt={1}>
        You must be at least 18 years old to use the Site. By using the Site,
        you represent and warrant that you are at least 18 years old and that
        you have the legal capacity to enter into these Terms.
      </Typography>
      <Typography variant="h6" fontWeight={500} mt={2}>
        User Account
      </Typography>
      <Typography mt={1}>
        To access certain features of the Site, you may be required to create an
        account. You agree to provide accurate, current, and complete
        information during the registration process and to update such
        information to keep it accurate, current, and complete. You are
        responsible for safeguarding your account password and for any
        activities or actions under your account. You agree to notify us
        immediately of any unauthorized use of your account.
      </Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Intellectual Property
      </Typography>
      <Typography mt={1}>
        The Site and its original content, features, and functionality are and
        will remain the exclusive property of ASSNTURE Limited - ADGM and its
        licensors. The Site is protected by copyright, trademark, and other laws
        of the United Arab Emirates and foreign countries. Our trademarks and
        trade dress may not be used in connection with any product or service
        without the prior written consent of ASSNTURE Limited - ADGM.
      </Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Prohibited Activities
      </Typography>
      <Typography mt={1}>
        You agree not to engage in any of the following prohibited activities:
        <br />
        <ul>
          <li>
            Copying, distributing, or disclosing any part of the Site in any
            medium, including without limitation by any automated or
            non-automated &quot;scraping&quot;
          </li>
          <li>
            Using any automated system, including without limitation
            &quot;robots,&quot; &quot;spiders,&quot; &quot;offline
            readers,&quot; etc., to access the Site
          </li>
          <li>Transmitting spam, chain letters, or other unsolicited email</li>
          <li>
            Attempting to interfere with, compromise the system integrity or
            security, or decipher any transmissions to or from the servers
            running the Site
          </li>
          <li>
            Taking any action that imposes, or may impose at our sole discretion
            an unreasonable or disproportionately large load on our
            infrastructure
          </li>
          <li>
            Uploading invalid data, viruses, worms, or other software agents
            through the Site
          </li>
          <li>
            Collecting or harvesting any personally identifiable information,
            including account names, from the Site
          </li>
          <li>Using the Site for any commercial solicitation purposes</li>
          <li>
            Impersonating another person or otherwise misrepresenting your
            affiliation with a person or entity, conducting fraud, hiding or
            attempting to hide your identity
          </li>
          <li>Interfering with the proper working of the Site</li>
          <li>
            Accessing any content on the Site through any technology or means
            other than those provided or authorized by the Site
          </li>
          <li>
            Bypassing the measures we may use to prevent or restrict access to
            the Site, including without limitation features that prevent or
            restrict use or copying of any content or enforce limitations on use
            of the Site or the content therein
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Termination
      </Typography>
      <Typography mt={1}>
        We may terminate or suspend your account and bar access to the Site
        immediately, without prior notice or liability, under our sole
        discretion, for any reason whatsoever and without limitation, including
        but not limited to a breach of the Terms.
      </Typography>
      <Typography mt={1.5}>
        If you wish to terminate your account, you may simply discontinue using
        the Site.
      </Typography>
      <Typography mt={1.5}>
        All provisions of the Terms which by their nature should survive
        termination shall survive termination, including, without limitation,
        ownership provisions, warranty disclaimers, indemnity, and limitations
        of liability.
      </Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Limitation of Liability
      </Typography>
      <Typography mt={1}>
        In no event shall ASSNTURE Limited - ADGM, nor its directors, employees,
        partners, agents, suppliers, or affiliates, be liable for any indirect,
        incidental, special, consequential, or punitive damages, including
        without limitation, loss of profits, data, use, goodwill, or other
        intangible losses, resulting from <strong>(i)</strong> your use or
        inability to use the Site; <strong>(ii)</strong> any unauthorized access
        to or use of our servers and/or any personal information stored therein;
        <strong>(iii)</strong> any interruption or cessation of transmission to
        or from the Site; <strong>(iv)</strong> any bugs, viruses, trojan
        horses, or the like that may be transmitted to or through the Site by
        any third party; <strong>(v)</strong> any errors or omissions in any
        content or for any loss or damage incurred as a result of your use of
        any content posted, emailed, transmitted, or otherwise made available
        through the Site; and/or <strong>(vi)</strong> the defamatory,
        offensive, or illegal conduct of any third party. In no event shall
        ASSNTURE Limited - ADGM, its directors, employees, partners, agents,
        suppliers, or affiliates be liable for any claims, proceedings,
        liabilities, obligations, damages, losses, or costs in an amount
        exceeding the amount you paid to ASSNTURE Limited - ADGM hereunder.
      </Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Disclaimer
      </Typography>
      <Typography mt={1}>
        Your use of the Site is at your sole risk. The Site is provided on an
        &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; basis. The Site is
        provided without warranties of any kind, whether express or implied,
        including, but not limited to, implied warranties of merchantability,
        fitness for a particular purpose, non-infringement, or course of
        performance.
      </Typography>
      <Typography mt={1.5}>
        ASSNTURE Limited - ADGM, its subsidiaries, affiliates, and its licensors
        do not warrant that <strong>(a)</strong> the Site will function
        uninterrupted, secure, or available at any particular time or location;
        <strong>(b)</strong> any errors or defects will be corrected;{' '}
        <strong>(c)</strong> the Site is free of viruses or other harmful
        components; or <strong>(d)</strong> the results of using the Site will
        meet your requirements.
      </Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Governing Law
      </Typography>
      <Typography mt={1}>
        These Terms shall be governed and construed in accordance with the laws
        of the Abu Dhabi Global Market (ADGM), United Arab Emirates, without
        regard to its conflict of law provisions.
      </Typography>
      <Typography mt={1.5}>
        Our failure to enforce any right or provision of these Terms will not be
        considered a waiver of those rights. If any provision of these Terms is
        held to be invalid or unenforceable by a court, the remaining provisions
        of these Terms will remain in effect. These Terms constitute the entire
        agreement between us regarding our Site and supersede and replace any
        prior agreements we might have had between us regarding the Site.
      </Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Changes to Terms
      </Typography>
      <Typography mt={1}>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material, we will try to provide at
        least 30 days&apos; notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole discretion.
      </Typography>
      <Typography mt={1.5}>
        By continuing to access or use our Site after any revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, you are no longer authorized to use the Site.
      </Typography>
      <Typography variant="h5" fontWeight={500} mt={4}>
        Contact Us
      </Typography>
      <Typography mt={1}>
        If you have any questions about these Terms, please contact us:
        <br />
        <ul>
          <li>
            By email: <a href="mailto:hello@assnture.com">hello@assnture.com</a>
          </li>
          <li>
            By visiting this page on our website:{' '}
            <a href="https://assnture.com">https://assnture.com</a>
          </li>
        </ul>
        These terms are designed to ensure a transparent and respectful
        interaction with our users. Please contact us if you have any questions
        or require further clarification.
      </Typography>
    </Container>
  );
};
