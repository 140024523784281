import React from 'react';
import { ThemeProvider } from '@mui/material';
import { RouterProvider } from 'react-router-dom';

import { theme } from './configs/theme';
import { router } from './routes/router';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default App;
