import React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import { AppLayout } from '../components/layouts/AppLayout';
import { LandingPage } from '../pages/landing/LandingPage';
import { TermsPage } from '../pages/terms/TermsPage';
import { PrivacyPolicyPage } from '../pages/privacy-policy/PrivacyPolicyPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '',
        index: true,
        element: <LandingPage />,
      },
      {
        path: 'terms',
        element: <TermsPage />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicyPage />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
]);
