import React from 'react';
import { Box, Container, Grid, Stack, Typography, styled } from '@mui/material';
import { CheckOutlined } from '@mui/icons-material';

import img from '../../assets/images/img6.svg';

const Image = styled('img')(() => ({
  maxWidth: '100%',
}));

export const ChooseUsSection: React.FC = () => {
  return (
    <Box id="choose-us" position="relative" py={6.25} bgcolor="#F8FAF5">
      <Container>
        <Typography
          variant="h2"
          fontSize={40}
          fontWeight={700}
          textAlign="center"
          className="__animated __flipInX"
        >
          WHY&nbsp;
          <Typography
            component="span"
            fontSize="inherit"
            fontWeight="inherit"
            bgcolor="primary.main"
          >
            &nbsp;CHOOSE US&nbsp;
          </Typography>
        </Typography>
        <Box mt={8}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6}>
              <Stack
                height="100%"
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  src={img}
                  alt="Choose Us"
                  className="__animated __fadeInLeft"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack
                height="100%"
                direction="column"
                justifyContent="center"
                gap={7}
              >
                <Stack
                  direction="row"
                  gap={1.5}
                  className="__animated __fadeInRight"
                >
                  <ListLeadingIcon />
                  <Box flex={1}>
                    <Typography
                      variant="h6"
                      fontSize={24}
                      fontWeight={700}
                      color="primary.main"
                    >
                      Expertise
                    </Typography>
                    <Typography mt={1} color="#4B4A62">
                      Our team has a proven track record in asset tokenization,
                      financial markets, institutional investments, blockchain,
                      asset management, and banking technologies within the
                      financial industry.
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  gap={1.5}
                  className="__animated __fadeInRight __delay __delay-100ms"
                >
                  <ListLeadingIcon />
                  <Box flex={1}>
                    <Typography
                      variant="h6"
                      fontSize={24}
                      fontWeight={700}
                      color="primary.main"
                    >
                      Regulations
                    </Typography>
                    <Typography mt={1} color="#4B4A62">
                      Our team excels in navigating the complex regulatory
                      landscape of asset tokenization. With deep expertise in
                      global financial regulations, we ensure full compliance
                      with all relevant legal frameworks.
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  gap={1.5}
                  className="__animated __fadeInRight __delay __delay-200ms"
                >
                  <ListLeadingIcon />
                  <Box flex={1}>
                    <Typography
                      variant="h6"
                      fontSize={24}
                      fontWeight={700}
                      color="primary.main"
                    >
                      Technology
                    </Typography>
                    <Typography mt={1} color="#4B4A62">
                      At the forefront of integrating cutting-edge technology in
                      asset tokenization. With extensive experience in
                      blockchain and the financial technology sector, we
                      leverage advanced tools to ensure secure, efficient, and
                      transparent processes.
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

const ListLeadingIcon = React.memo(function ListLeadingIcon() {
  return (
    <Box
      width={40}
      height={40}
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="#F4D9C1"
      borderRadius="100%"
    >
      <CheckOutlined
        sx={{
          width: 20,
          height: 20,
          color: 'primary.main',
        }}
      />
    </Box>
  );
});
