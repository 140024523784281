import { Components, Theme } from '@mui/material';

export const MuiButton: Components<Omit<Theme, 'components'>>['MuiButton'] = {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      textTransform: 'none',
      borderRadius: 8,
    },
    sizeMedium: {
      fontSize: '1rem',
    },
    sizeLarge: {
      fontSize: '1rem',
    },
    containedPrimary: {
      color: 'white',
    },
  },
};
