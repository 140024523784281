import React, { useCallback } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  styled,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { FormatQuote } from '@mui/icons-material';

import { ReactComponent as SvgIcon1 } from '../../assets/images/icon1.svg';
import { ReactComponent as SvgIcon2 } from '../../assets/images/icon2.svg';
import { ReactComponent as SvgIcon3 } from '../../assets/images/icon3.svg';
import { ReactComponent as SvgIcon4 } from '../../assets/images/icon4.svg';
import { ReactComponent as SvgIcon5 } from '../../assets/images/icon5.svg';

const Accordion = styled(MuiAccordion)(({ expanded }) => ({
  boxShadow: 'none',
  borderRadius: '8px !important',
  backgroundColor: expanded ? '#F9F9F9' : '#FFFFFF',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: expanded ? '#D0D2CE' : 'transparent',
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: 16,
  paddingBottom: expanded ? 0 : 16,
  maxWidth: 520,
  boxSizing: 'border-box',

  '.MuiAccordionSummary-root': {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 'auto',

    '& .MuiAccordionSummary-content': {
      margin: 0,

      '& h6': {
        fontSize: 24,
        fontWeight: 500,
        color: '#303030',
      },
    },
  },

  '.MuiAccordionDetails-root': {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 24,
    paddingTop: 16,
    fontFamily: 'Poppins',
  },

  '&::before': {
    display: 'none',
  },
}));

export const ServicesSection: React.FC = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const [expanded, setExpanded] = React.useState<string>('service-1');

  const handleChange = useCallback((key: string, opened: boolean) => {
    if (opened) {
      setExpanded(key);
    }
  }, []);

  return (
    <Box id="services" position="relative" py={6.25}>
      <Container>
        <Typography
          variant="h2"
          fontSize={40}
          fontWeight={700}
          className="__animated __flipInX"
        >
          OUR COMPREHENSIVE&nbsp;
          {isSm && <br />}
          <Typography
            component="span"
            fontSize="inherit"
            fontWeight="inherit"
            bgcolor="primary.main"
          >
            &nbsp;RANGE OF SERVICES&nbsp;
          </Typography>
        </Typography>
        <Box pt={5}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6}>
              <Stack height="100%" justifyContent="center" spacing={2.5}>
                <Box className="__animated __fadeInLeft">
                  <Accordion
                    expanded={expanded === 'service-1'}
                    onChange={(_, value) => handleChange('service-1', value)}
                  >
                    <AccordionSummary expandIcon={null}>
                      <Stack direction="row" alignItems="center" spacing={1.5}>
                        <SvgIcon1 />
                        <Typography variant="h6">Asset Tokenization</Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      Transform your real-world assets - from real estate to
                      art, from equity to commodities - into digital tokens that
                      can be traded 24/7, unlocking liquidity and lowering
                      barriers to entry for investors.
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box className="__animated __fadeInLeft __delay __delay-100ms">
                  <Accordion
                    expanded={expanded === 'service-2'}
                    onChange={(_, value) => handleChange('service-2', value)}
                  >
                    <AccordionSummary expandIcon={null}>
                      <Stack direction="row" alignItems="center" spacing={1.5}>
                        <SvgIcon2 />
                        <Typography variant="h6">
                          Compliance Solutions
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      We ensure that all tokenization processes adhere to local
                      and international regulations, providing a compliant
                      framework for asset owners and investors.
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box className="__animated __fadeInLeft __delay __delay-200ms">
                  <Accordion
                    expanded={expanded === 'service-3'}
                    onChange={(_, value) => handleChange('service-3', value)}
                  >
                    <AccordionSummary expandIcon={null}>
                      <Stack direction="row" alignItems="center" spacing={1.5}>
                        <SvgIcon4 />
                        <Typography variant="h6">
                          Tokenization Platform
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      Our state-of-the-art tokenization platform OPTIMUS
                      provides a secure and user-friendly interface for asset
                      owners to tokenize and manage their assets efficiently.
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box className="__animated __fadeInLeft __delay __delay-300ms">
                  <Accordion
                    expanded={expanded === 'service-4'}
                    onChange={(_, value) => handleChange('service-4', value)}
                  >
                    <AccordionSummary expandIcon={null}>
                      <Stack direction="row" alignItems="center" spacing={1.5}>
                        <SvgIcon3 />
                        <Typography variant="h6">Liquidity Provider</Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      Access institutional scale liquidity, listing assets on
                      your marketplace and through ASSNTURE Institutional
                      Marketplace giving you access to higher liquidity for your
                      assets.
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box className="__animated __fadeInLeft __delay __delay-400ms">
                  <Accordion
                    expanded={expanded === 'service-5'}
                    onChange={(_, value) => handleChange('service-5', value)}
                  >
                    <AccordionSummary expandIcon={null}>
                      <Stack direction="row" alignItems="center" spacing={1.5}>
                        <SvgIcon5 />
                        <Typography variant="h6">Secondary Market</Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      Access a global marketplace for trading digital asset
                      tokens, providing instant liquidity to asset owners and
                      investment opportunities to a wider audience.
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                sx={{
                  borderRadius: 2,
                  bgcolor: '#F6FCFF',
                  px: isSm ? 4 : 10,
                  pb: 6,
                  pt: 2.5,
                }}
                className="__animated __zoomIn"
              >
                <Stack direction="row" justifyContent="center">
                  <FormatQuote
                    sx={{
                      color: 'grey.500',
                      width: 50,
                      height: 50,
                    }}
                  />
                </Stack>
                <Typography
                  mt={2.5}
                  color="primary.main"
                  variant="h6"
                  fontSize={32}
                  fontWeight={700}
                  lineHeight={1.25}
                >
                  Ihsan Khelef Co-Founder & MD
                </Typography>
                <Typography
                  mt={1}
                  color="grey.500"
                  fontSize={18}
                  lineHeight={1.75}
                >
                  Asset tokenization will help thousands of market players to
                  democratize investments by converting assets into digital
                  tokens, making them accessible to mass investment. The
                  fractional ownership, increased transparency and lowered entry
                  barriers, will make investment available to a much wider
                  investor spectrum, unlocking massive opportunities that
                  weren’t available to date.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};
